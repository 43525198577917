<template>
  <hcc-modal name="edit-modal"
    :title="isAuth ? $t('ticketModal.validation') : $t('ticketModal.edit')"
    :scrollable="false" @before-open="beforeOpen" @confirm="onConfirm"
    :hideOnConfirm="false">
    <div class="ticket-modal">
      <div class="ticket-modal__image">
        <a :href="ticket.image" target="_blank">
          <img :src="ticket.image" alt="ticket" />
        </a>
      </div>
      <div class="ticket-modal__item">
        <hcc-select name="store" :label="$t('ticketModal.store')"
          :placeholder="$t('ticketModal.selectStore')" :options="stores"
          optionLabel="id" track-by="name" v-model="selectedStore"
          custom-class="custom-select" requiredInput
          :error="!!errorsForName('store')"
          :errorMessage="errorMessageForName('store')" />
      </div>
      <div class="ticket-modal__item">
        <div class="ticket-detail__info">
          <label class="select__label select__label-required">{{
              $t('ticketModal.purchase')
          }}</label>
          <hcc-date-picker :startDate="date" :endDate="date"
            :error="!!errorsForName('date')"
            :errorMessage="errorMessageForName('date')"
            @update="updateDate"/>
        </div>
      </div>
      <div class="ticket-modal__item">
        <hcc-input :label="$t('tickets.ticketNum')" v-model="number" size="xlg"
          requiredInput :error="!!errorsForName('number')"
          :errorMessage="errorMessageForName('number')"
          @input="errors = validateForm()" />
      </div>
      <div class="ticket-modal__item">
        <hcc-input :label="$t('ticketModal.total')" v-model="total" size="xlg"
          :error="!!errorsForName('total')"
          :errorMessage="errorMessageForName('total')"
          @input="errors = validateForm()" requiredInput />
      </div>
      <div class="ticket-modal__toggle">
        <label class="select__label">{{
            $t('ticketModal.review')
        }}</label>
        <hcc-toggle-switch name="review" :disabled="ticket.status === 'review'"
          v-model="review" />
      </div>
      <div class="ticket-modal__dropzone">
        <div class="ticket-modal__dropzone-container">
          <div class="ticket-detail__title">{{
              $t('ticketModal.picture')
          }}</div>
          <div class="ticket-modal__description">{{
              $t('ticketModal.replaceTicket')
          }}
          </div>
        </div>
        <vue-file-agent ref="dropZone" :compact="false" :deletable="true"
          :helpText="$t('dropzone.text')" :maxFiles="1" :meta="false"
          :multiple="false" @select="onFileSelected"
          @beforedelete="onDeleteFile($event)" class="ticket-modal__dropzone" />
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../HccModal/index.vue'
import HccSelect from '../HccSelect.vue'
import HccInput from '../HccInput.vue'
import HccDatePicker from '../HccDatePicker.vue'
import HccToggleSwitch from '../HccToggleSwitch.vue'
import { mapState } from 'vuex'

export default {
  components: { HccModal, HccSelect, HccInput, HccDatePicker, HccToggleSwitch },
  data() {
    return {
      ticket: {},
      stores: [
        { id: 'Tienda 1', name: 'Tienda 1' },
        { id: 'Tienda 2', name: 'Tienda 2' },
        { id: 'Tienda 3', name: 'Tienda 3' },
        { id: 'Tienda 4', name: 'Tienda 4' }
      ],
      selectedStore: null,
      number: null,
      total: null,
      review: false,
      date: null,
      file: null,
      errors: [],
      showErrors: false,
      isAuth: null
    }
  },
  computed: {
    ...mapState({
      tickets: state => state.tickets
    })
  },
  methods: {
    beforeOpen(event) {
      this.ticket = event.params.ticket
      this.isAuth = event.params.auth
      this.setData()
    },
    setData() {
      this.selectedStore = this.ticket?.store ? this.stores.find((store) => store.id === this.ticket.store) : null
      this.number = this.ticket?.numTicket || null
      this.total = this.ticket?.amount || null
      this.review = this.ticket.status === 'review'

      if (this.ticket?.date) {
        const date = new Date(this.ticket.date)
        date.setDate(date.getDate() + 1)
        this.date = date
      }
    },
    onFileSelected(file) {
      this.file = file[0].file
    },
    onDeleteFile(file) {
      this.$refs.dropZone.deleteFileRecord(file)
    },
    validateForm() {
      const errors = []
      if (!this.selectedStore) {
        errors.push({ name: 'store', error: this.$t('ticketModal.rules.store') })
      }
      if (!this.date) {
        errors.push({ name: 'date', error: this.$t('ticketModal.rules.date') })
      }
      if (!this.number) {
        errors.push({ name: 'number', error: this.$t('ticketModal.rules.number') })
      }
      if (this.tickets.some(({ id, numTicket }) => id !== this.ticket.id && numTicket === this.number)) {
        errors.push({ name: 'number', error: this.$t('ticketModal.rules.unique') })
      }
      if (!this.total) {
        errors.push({ name: 'total', error: this.$t('ticketModal.rules.total') })
      }
      if (!(/^[0-9]*$/.test(this.total)) || parseInt(this.total, 10) <= 0) {
        errors.push({ name: 'total', error: this.$t('ticketModal.rules.positive') })
      }
      return errors
    },
    errorsForName(name) {
      if (this.showErrors) {
        return this.errors.find(err => err.name === name)
      }
    },
    errorMessageForName(name) {
      if (this.showErrors) {
        return this.errors.find(err => err.name === name)?.error
      }
    },
    updateDate(params) {
      this.date = params
    },
    async updateStatus(tickedId, status, userId) {
      const url = 'https://core.2.sellia.com.mx'
      const headers = { 'Content-Type': 'application/json' }

      const { token } = await fetch(`${url}/api/v1/login/manager`, {
        method: 'POST',
        body: JSON.stringify({
          username: 'telegram',
          password: 'holamundo'
        }),
        headers
      }).then((res) => res.json())

      headers.Authorization = `Bearer ${token}`
      const data = {
        channel: '631b5b88b395142e9e1ec0ad',
        template: '636bcf05aa70661fde02ebbf',
        clients: [
          {
            username: '+' + userId,
            params: ['Ticket', status],
            labels: [
              {
                key: 'Ticket',
                value: tickedId
              }
            ]
          }
        ]
      }

      if (status === 'rejected') {
        data.template = '636d94f12866989553c2075b'
      }

      return fetch(`${url}/api/v1/templates/send`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers
      })
        .then((res) => res.json())
    },
    onConfirm() {
      this.errors = this.validateForm()
      this.showErrors = true

      if (this.errors.length === 0) {
        delete this.ticket.vgt_id
        delete this.ticket.originalIndex

        const payload = {
          ...this.ticket,
          amount: this.total,
          date: this.$date(this.date, 'yyyy-MM-dd'),
          file: this.file,
          numTicket: this.number,
          store: this.selectedStore.id
        }

        console.log(payload)

        const index = this.tickets.findIndex((ticket) => ticket.id === payload.id)
        payload.created_at = this.tickets[index].created_at

        if (this.review && this.ticket.status !== 'review') {
          payload.inReview = true
        }

        if (this.isAuth) {
          this.$store.dispatch('createIntent', payload)
        } else {
          this.$store.dispatch('updateTicket', payload)
        }

        if (this.isAuth || this.ticket.status === 'rejected') {
          const status = this.ticket.status
          const ticketId = this.ticket.id
          const userId = this.ticket.user.userId
          console.log(this.ticket)
          this.updateStatus(ticketId, status, userId)
        } else {
          console.log('out', this.ticket)
        }

        this.$modal.hide('edit-modal')
      }
    }

  }
}
</script>

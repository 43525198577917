import { i18n } from '../i18n'

const getStatus = (status) => {
  if (status === 'auth') {
    return i18n.t('tickets.status.auth')
  }
  if (status === 'rejected') {
    return i18n.t('tickets.status.rejected')
  }
  if (status === 'review') {
    return i18n.t('tickets.status.review')
  }

  return i18n.t('tickets.status.pending')
}

const getStatusColor = (status) => {
  if (status === 'auth') return 'alert-green'
  else if (status === 'rejected') return 'alert'
  else if (status === 'pending') return 'yellow'

  return 'orange'
}

export { getStatus, getStatusColor }

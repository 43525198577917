<template>
  <hcc-modal name="reject-modal" :title="$t('ticketModal.reject')"
    :scrollable="false" :disableConfirmButton="comments.length === 0"
    @before-open="beforeOpen" @confirm="onConfirm" @closed="comments = ''">
    <div class="ticket-modal__item">
      <hcc-input :label="$t('ticketModal.motive')" v-model="comments" size="xlg"
        requiredInput />
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../HccModal/index.vue'
import HccInput from '../HccInput.vue'
import { mapState } from 'vuex'

export default {
  components: { HccModal, HccInput },
  props: {
    comment: {
      type: String
    }
  },
  data() {
    return {
      ticket: {},
      comments: ''
    }
  },
  computed: {
    ...mapState({
      tickets: state => state.tickets
    })
  },
  methods: {
    beforeOpen(event) {
      this.ticket = event.params
    },
    async updateStatus(tickedId, status, userId) {
      const url = 'https://core.2.sellia.com.mx'
      const headers = { 'Content-Type': 'application/json' }

      const { token } = await fetch(`${url}/api/v1/login/manager`, {
        method: 'POST',
        body: JSON.stringify({
          username: 'telegram',
          password: 'holamundo'
        }),
        headers
      }).then((res) => res.json())

      headers.Authorization = `Bearer ${token}`
      const data = {
        channel: '631b5b88b395142e9e1ec0ad',
        template: '636d94f12866989553c2075b',
        clients: [
          {
            username: userId,
            params: [],
            labels: []
          }
        ]
      }

      return fetch(`${url}/api/v1/templates/send`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers
      })
        .then((res) => res.json())
    },
    onConfirm() {
      if (this.ticket.status === 'review') {
        const payload = {
          comment: this.comments,
          id: this.ticket.id,
          status: 'rejected'
        }

        this.$store.dispatch('reviewTicket', payload)
      }

      if (this.ticket.status === 'pending') {
        // Delete vue good table keys
        delete this.ticket.vgt_id
        delete this.ticket.originalIndex

        const payload = {
          ...this.ticket,
          comment: this.comments
        }

        const index = this.tickets.findIndex((ticket) => ticket.id === payload.id)
        payload.created_at = this.tickets[index].created_at

        this.$store.dispatch('rejectTicket', payload)
      }

      this.updateStatus(this.ticket.id, this.ticket.status, this.ticket.user.userId)
    }
  }
}
</script>

<template>
  <div>
    <overlay-loader :loading="loading" />
    <hcc-table :title="$t('tickets.title')" :columns="columns"
      :rows="formattedTickets" :rowsPerPage="10"
      :pagination="formattedTickets.length > 10" enableSearch
      @search="() => { }">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'status'" class="ticket__status">
          <hcc-badge v-tooltip="getStatus(props.row.status)"
            :color="getStatusColor(props.row.status)" size="xs" fill circle />
        </div>
        <div v-else-if="props.column.field == 'ticket-actions'"
          class="ticket__actions">
          <hcc-button-icon @click="viewTicketDetail(props.row)"
            v-tooltip="$t('ticketModal.viewDetail')">
            <eye-icon />
          </hcc-button-icon>
          <template
            v-if="props.row.status === 'pending' || props.row.status === 'review'">
            <hcc-dropdown position="bottom-left">
              <template #button>
                <hcc-button-icon class="ticket__actions-dots"
                  v-tooltip="$t('tickets.options')">
                  <dots-icon />
                </hcc-button-icon>
              </template>
              <hcc-dropdown-item v-if="props.row.status === 'review'"
                @click="editTicket(props.row, false)">
                <span>{{ $t('tickets.edit') }}</span>
              </hcc-dropdown-item>
              <hcc-dropdown-item @click="editTicket(props.row, true)">
                <span>{{ $t('tickets.auth') }}</span>
              </hcc-dropdown-item>
              <hcc-dropdown-item @click="rejectTicket(props.row)">
                <span>{{ $t('tickets.reject') }}</span>
              </hcc-dropdown-item>
            </hcc-dropdown>
          </template>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <detail-modal />
    <reject-modal :comment="currentTicket.comment" />
    <edit-modal />
  </div>
</template>

<script>
import HccTable from '../components/HccTable.vue'
import HccBadge from '../components/HccBadge.vue'
import HccDropdown from '../components/HccDropdown/index.vue'
import HccDropdownItem from '../components/HccDropdown/HccDropdownItem.vue'
import OverlayLoader from '../components/OverlayLoader.vue'
import HccButtonIcon from '../components/HccButtonIcon.vue'
import EyeIcon from '@/assets/icons/eye.svg'
import DotsIcon from '@/assets/icons/dots-horizontal.svg'
import RejectModal from '../components/TicketModals/RejectModal.vue'
import DetailModal from '../components/TicketModals/DetailModal.vue'
import EditModal from '../components/TicketModals/EditModal.vue'
import { getStatus, getStatusColor } from '../utils/helpers'
import { mapState } from 'vuex'
import { defaultLang } from '../i18n'

export default {
  components: { HccTable, HccBadge, HccButtonIcon, HccDropdown, HccDropdownItem, OverlayLoader, EyeIcon, DotsIcon, RejectModal, DetailModal, EditModal },
  name: 'TicketsTable',
  data() {
    return {
      currentTicket: {
        store: null,
        date: null,
        products: [],
        amount: 0
      },
      defaultLang,
      getStatus,
      getStatusColor,
      search: ''
    }
  },
  created() {
    this.$crontab.addJob({
      name: 'tickets',
      interval: {
        seconds: '/20'
      },
      job: this.fetchTickets
    })
  },
  mounted() {
    this.$store.dispatch('getTickets', {})
    this.currentTicket.products.push({ sku: 'SKU-TEST', amount: 10 })
  },
  computed: {
    ...mapState({
      tickets: state => state.tickets,
      loading: state => state.loading
    }),
    columns() {
      return [
        {
          label: this.$t('tickets.status.title'),
          field: 'status'
        },
        {
          label: this.$t('tickets.id'),
          field: 'id'
        },
        {
          label: this.$t('tickets.ticketNum'),
          field: 'numTicket'
        },
        {
          label: this.$t('tickets.name'),
          field: 'user.userName',
          sortable: false
        },
        {
          label: this.$t('tickets.phone'),
          field: 'user.phone'
        },
        {
          label: this.$t('tickets.email'),
          field: 'user.email'
        },
        {
          label: this.$t('tickets.createdAt'),
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'dd-MM-yy hh:mma',
          dateOutputFormat: 'dd-MM-yy hh:mma'
        },
        {
          label: this.$t('tickets.actions'),
          field: 'ticket-actions',
          sortable: false
        }
      ]
    },
    formattedTickets() {
      return this.tickets.map((ticket) => {
        return { ...ticket, created_at: this.formatDate(ticket.created_at) }
      })
    }
  },
  methods: {
    formatDate(ticketDate) {
      const date = new Date((ticketDate.seconds * 1000) + (ticketDate.nanoseconds / 1000000))
      return this.$date(this.isValidDate(date) ? date : new Date(), 'dd-MM-yy hh:mma')
    },
    fetchTickets() {
      this.$store.dispatch('syncTickets', {})
    },
    isValidDate(date) {
      return (date instanceof Date) && !isNaN(date)
    },
    editTicket(ticket, auth) {
      this.$modal.show('edit-modal', { ticket, auth })
    },
    rejectTicket(ticket) {
      this.$modal.show('reject-modal', ticket)
    },
    viewTicketDetail(ticket) {
      this.$modal.show('detail-modal', ticket)
    }
  }
}
</script>

<style lang="scss">
@import "~styles/views/tickets.scss";
</style>

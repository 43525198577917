<template>
  <span>
    <slot name="layout">
      <li :class="itemClass" @click="$emit('click')">
        <component v-if="icon" :is="icon" class="dropdown__icon" />
        <slot />
      </li>
    </slot>
  </span>
</template>
<script>
export default {
  props: {
    icon: String,
    unclickable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemClass() {
      return [
        'dropdown__item',
        {
          unclickable: this.unclickable,
          selected: this.selected,
          disabled: this.disabled
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/components/dropdown.scss";
</style>;

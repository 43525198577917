<template>
  <date-range-picker ref="picker" v-model="dateRange"
    :auto-apply="dateProps.autoApply"
    :always-show-calendars="dateProps.alwaysShowCalendars"
    :linked-calendars="dateProps.linkedCalendars"
    :locale-data="dateProps.localeData" :max-date="dateProps.maxDate"
    :min-date="getMinDate" :opens="dateProps.opens" :ranges="rangesList"
    :show-dropdowns="dateProps.showDropdowns" :single-date-picker="true"
    :show-week-numbers="true" @update="update" @toggle="toggleOpen"
    @select="select" :class="{ 'datepicker-open': isOpen, disabled }">
    <template v-slot:input="picker">
      <span>{{ humanReadable(picker.startDate, picker.endDate) }}</span>
    </template>
  </date-range-picker>
</template>

<script>
import moment from 'moment'

export default {
  components: {
    DateRangePicker: () => import('vue2-daterange-picker')
  },
  props: {
    startDate: {
      required: true,
      default: () => moment().toDate()
    },
    endDate: {
      required: true,
      default: () => moment().toDate()
    },
    options: {
      default: () => ({}),
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoSelectSecond: {
      type: Boolean,
      default: false
    },
    rangeDays: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentCompany: null,
      currentCampaign: null,
      currentAgent: null,
      dateRange: {
        startDate: this.startDate,
        endDate: this.endDate
      },
      dateProps: {
        alwaysShowCalendars: false,
        autoApply: true,
        linkedCalendars: true,
        localeData: {
          format: 'DD-MM-YYYY',
          applyLabel: this.$t('range-picker.apply'),
          cancelLabel: this.$t('range-picker.cancel'),
          customRangeLabel: this.$t('range-picker.custom'),
          daysOfWeek: moment.localeData().weekdaysMin(),
          monthNames: moment.localeData().monthsShort(),
          singleDatePicker: true,
          firstDay: 0
        },
        maxDate: moment().toDate(),
        opens: 'center',
        showDropdowns: true,
        ...this.options
      },
      ranges: [],
      isOpen: false
    }
  },
  watch: {
    startDate(newValue) {
      this.dateRange.startDate = newValue
    },
    endDate(newValue) {
      this.dateRange.endDate = newValue
    }
  },
  computed: {
    rangesList() {
      const ranges = {}

      if (!this.options.singleDatePicker) {
        this.ranges.forEach((range) => {
          ranges[this.$t(range.name)] = [range.from, range.to]
        })
      }

      return ranges
    },
    getMinDate() {
      return moment().subtract(1, 'year').toDate()
    }
  },
  methods: {
    findSelectedRange(start, end) {
      return this.ranges.find((range) => {
        const from = moment(range.from)
        const to = moment(range.to)

        return start.isSame(from, 'day') && end.isSame(to, 'day')
      })
    },
    humanReadable(from, to) {
      const dtFrom = moment(from)
      const dtTo = moment(to)

      if (dtFrom.isValid() && dtTo.isValid()) {
        const range = this.findSelectedRange(dtFrom, dtTo)

        if (range !== undefined) {
          return this.$t(range.name)
        }

        if (this.autoSelectSecond) {
          return this.rangeDays === 0
            ? dtFrom.format('D MMM YYYY')
            : `${dtFrom.format('D MMM YYYY')} - ${dtFrom.add(this.rangeDays, 'days').format('D MMM YYYY')}`
        }

        return !dtFrom.isSame(dtTo, 'day')
          ? `${dtFrom.format('D MMM YYYY')} - ${dtTo.format('D MMM YYYY')}`
          : dtFrom.format('D MMM YYYY')
      }

      return this.$t('range-picker.unset')
    },
    update(dates) {
      this.$emit('update', dates.startDate, dates.endDate)
    },
    select() {
      this.$emit('select')
    },
    reset() {
      this.dateRange.startDate = null
      this.dateRange.endDate = null
    },
    toggleOpen() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .datepicker-open {
  .reportrange-text {
    border-color: $sellia-secondary;
    transition: all ease 80ms;
    background-image: url($icons-dir + "caret-up.svg");
  }
}
</style>

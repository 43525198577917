<template>
  <hcc-modal name="detail-modal" :title="$t('ticketModal.detail')"
    :scrollable="false" :buttons="['exit']" @before-open="beforeOpen">
    <div class="ticket-detail">
      <div class="ticket-detail__image">
        <a :href="ticket.image" target="_blank">
          <img :src="ticket.image" alt="ticket" />
        </a>
      </div>
      <div class="ticket-detail__group">
        <div class="ticket-detail__info">
          <div class="ticket-detail__title">{{ $t('tickets.id') }}</div>
          <div class="ticket-detail__description">
            <hcc-button-icon class="ticket-detail__copy-icon"
              v-clipboard:copy="ticket.id">
              <copy-icon />
            </hcc-button-icon>
            {{ ticket.id }}
          </div>
        </div>
        <div class="ticket-detail__badge">
          <hcc-badge :text="getStatus(ticket.status)"
            :color="getStatusColor(ticket.status)" fill rounded />
        </div>
      </div>
      <div class="ticket-detail__info" v-if="ticket.numTicket">
        <div class="ticket-detail__title">{{ $t('tickets.ticketNum') }}</div>
        <div class="ticket-detail__description">
          <hcc-button-icon class="ticket-detail__copy-icon"
            v-clipboard:copy="ticket.numTicket">
            <copy-icon />
          </hcc-button-icon>{{ ticket.numTicket }}
        </div>
      </div>
      <div v-if="ticket.status === 'rejected'" class="ticket-detail__review">
        <div class="ticket-detail__info">
          <div class="ticket-detail__title">{{ $t('ticketModal.motive') }}</div>
          <div class="ticket-detail__description-auth">
            {{ ticket.comment }}
          </div>
        </div>
      </div>

      <div v-if="ticket.status === 'auth'" class="ticket-detail__review">
        <div class="ticket-detail__info">
          <div class="ticket-detail__title">{{ $t('ticketModal.store') }}</div>
          <div class="ticket-detail__description-auth">
            {{ ticket.store }}
          </div>
        </div>
        <div class="ticket-detail__info">
          <div class="ticket-detail__title">{{ $t('ticketModal.purchase') }}
          </div>
          <div class="ticket-detail__description-auth">
            {{ ticket.date }}
          </div>
        </div>
        <div class="ticket-detail__info">
          <div class="ticket-detail__title">{{ $t('ticketModal.total') }}</div>
          <div class="ticket-detail__description-auth">
            {{ ticket.amount }}
          </div>
        </div>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../HccModal/index.vue'
import HccBadge from '../HccBadge.vue'
import HccButtonIcon from '../HccButtonIcon.vue'
import CopyIcon from '@/assets/icons/copy.svg'
import { getStatus, getStatusColor } from '../../utils/helpers'

export default {
  components: { HccModal, HccBadge, HccButtonIcon, CopyIcon },
  data() {
    return {
      ticket: {},
      getStatus,
      getStatusColor
    }
  },
  methods: {
    beforeOpen(event) {
      this.ticket = event.params
    }
  }
}
</script>
